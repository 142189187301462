import * as Turbo from '@hotwired/turbo'
Turbo.session.drive = false
// Turbo.start()

// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//

import '~/application.css'

import '../../assets/stylesheets/bootstrap.scss'
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

import '../src/owl.carousel.min'

import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from "@fortawesome/fontawesome-svg-core";
import '@fortawesome/fontawesome-free'
library.add(fas, far, fab)

document.addEventListener('turbo:load', () => {
    $(document).ready(function () {
        $(".owl-carousel").owlCarousel({
            loop: false,
            rewind: true,
            nav: true,
            margin: 10,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            responsiveClass: true,
            responsive:{
                0: {
                    items:2,
                },
                600: {
                    items:3,
                },
                1000: {
                    items:5,
                }
            },
        });
    });
})

var prevScrollpos = window.pageYOffset;

window.onscroll = function() {
    var currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos || currentScrollPos < 200) {
        document.querySelector("header").classList.remove("opacity-0");
    } else {
        document.querySelector("header").classList.add("opacity-0");
    }
    prevScrollpos = currentScrollPos;
}
